<script setup>
import userListModal from '@/components/client/modals/UserList.vue'
import { messageTemplateToHTML, useLocaleFormatter } from '@/utils'
import { debounce } from 'lodash-es'
import { computed, onBeforeUnmount, onMounted, ref, toRef, watch } from 'vue'
import { useUserStore } from '@/store'
import { storeToRefs } from 'pinia'
import { AdminNominationCampaigns, AdminCelebrations } from '@/api'
import { useToast } from 'vue-toastification'
import { useModal } from '@/modal'

const PREVIEW_USER_THRESHOLD = 3

const { formatList } = useLocaleFormatter()
const { openModal } = useModal()

const userStore = useUserStore()
const { user: currentUser } = storeToRefs(userStore)
const toast = useToast()

const props = defineProps({
  data: Object,
  timelineMode: { type: Boolean, default: false },
  preview: {
    type: Boolean,
    default: false,
  },
  embedded: {
    type: Boolean,
    default: false,
  },
})

const send = ref({ ...props.data })
const rendering = ref(false)
const cardRendering = ref(false)
const messageContent = ref(null)
const isPreview = ref(props.preview)
const isEmbedded = ref(props.embedded)
const generatedCard = ref(null)
const giftCard = ref(null)

const triggerTitle = ref()
const triggerTitleLoading = ref(false)
watch(
  props.data,
  (v) => {
    send.value = {
      ...v,
      sender: isPreview.value ? currentUser.value : v.sender,
    }
  },
  { deep: true }
)

if (isPreview.value && !props.nominationCampaign) {
  send.value.sender = currentUser.value
}

const forUsers = toRef(props.data.recipients)

const renderMessage = async () => {
  const elem = messageContent.value

  let nodes
  if (isPreview.value === true) {
    // Pass template through recognition service to generate preview
    const [err, resp] = await AdminCelebrations.getMessagePreview({
      userIds: forUsers.value.map(({ id }) => id),
      messageTemplate: {
        message: send.value.message,
        name_template: send.value.name_template,
      },
      messageType: send.value.type,
    })
    send.value.users = forUsers.value

    if (err) {
      // Handle error
    }

    nodes = messageTemplateToHTML({
      post: { message: resp.message },
      relevantUsers: send.value.users,
    })
  } else {
    nodes = messageTemplateToHTML({
      post: send.value,
      relevantUsers: send.value.relevant_users,
    })
  }
  console.log(nodes)
  elem.replaceChildren(...nodes)

  rendering.value = false
}

const generateCardPreview = async () => {
  if (isPreview.value !== true) return

  if (!send.value.card_template) return

  const [cardErr, cardResp] = await AdminCelebrations.getGeneratedCardPreview({
    userIds: forUsers.value.map(({ id }) => id),
    cardTemplate: send.value.card_template,
    messageType: send.value.type,
  })

  if (cardErr) {
    // HAndle error
  }

  generatedCard.value = URL.createObjectURL(cardResp)
  cardRendering.value = false
}

const handleTrigger = async () => {
  const triggerId = send.value.triggered_by ?? send.value.trigger
  if (!triggerId) return
  console.log({ triggerId })
  let [err, resp] = [undefined, undefined]
  switch (true) {
    case triggerId.startsWith('nominationCampaign'):
      triggerTitleLoading.value = true
      ;[err, resp] = await AdminNominationCampaigns.getByID(triggerId)
      if (err) {
        toast.error(
          'Ran into loading nomination campign info for spot celebration'
        )
        break
      }
      triggerTitle.value = resp.title
      console.log({ triggerTitle: triggerTitle.value })
      triggerTitleLoading.value = false
      break
    default:
      triggerTitle.value = 'Special announcement'
      break
  }
}

const reRenderContent = debounce(renderMessage, 250)
const reGenerateCardPreview = debounce(generateCardPreview, 500)

watch(
  () => send.value.message + send.value.name_template,
  () => {
    rendering.value = true
    reRenderContent()
  }
)

watch(
  () => send.value.card_template,
  () => {
    cardRendering.value = true
    reGenerateCardPreview()
  }
)

watch(
  forUsers,
  () => {
    rendering.value = true
    cardRendering.value = true
    reRenderContent()
    reGenerateCardPreview()
  },
  { deep: true }
)

onMounted(() => {
  handleTrigger()
  renderMessage()
  generateCardPreview()
})

const recipientPreview = computed(() =>
  forUsers.value.length > PREVIEW_USER_THRESHOLD
    ? forUsers.value.slice(0, PREVIEW_USER_THRESHOLD)
    : forUsers.value
)

const postTitle = computed(() => {
  const recipientsForText =
    forUsers.value.length > recipientPreview.value.length
      ? [
          ...recipientPreview.value,
          `${forUsers.value.length - recipientPreview.value.length} more`,
        ]
      : recipientPreview.value

  return `
        ${formatList(
          recipientsForText.map((u) => {
            if (typeof u === 'string')
              return `<a href="#" class="addl-recipients-text">${u}</a>`

            return u.id === currentUser.value.id
              ? '<strong>you</strong>'
              : `<strong>${u.full_name}</strong>`
          })
        )}`
})

function handleAddlUserClick(e) {
  e.preventDefault()

  // Open modal showing users
  openModal(userListModal, {
    users: forUsers.value,
    title: 'Spot Celebration Recipients',
  })
}

onMounted(() => {
  const addlRecipientLink = giftCard.value.querySelector(
    '.addl-recipients-text'
  )
  if (!addlRecipientLink) return

  addlRecipientLink.addEventListener('click', handleAddlUserClick)
})

onBeforeUnmount(() => {
  const addlRecipientLink = giftCard.value.querySelector(
    '.addl-recipients-text'
  )
  if (!addlRecipientLink) return

  addlRecipientLink.removeEventListener('click', handleAddlUserClick)
})
</script>

<template>
  <div ref="giftCard" :class="['gift-card', { preview: isPreview }]">
    <div class="header">
      <div v-if="!triggerTitleLoading" class="users">
        <strong v-if="triggerTitle">
          {{ triggerTitle }}
        </strong>
        <user-card v-else :user="send.sender"> </user-card>
        <i class="fas fa-caret-right"></i>
        <ul
          v-if="
            forUsers.length <= PREVIEW_USER_THRESHOLD && !send.recipient_segment
          "
          class="recipients-under-threshold"
        >
          <li
            v-for="recipient in send.recipients"
            :key="`giftCard_recipient_${recipient.id}`"
          >
            <user-card :user="recipient" />
          </li>
        </ul>
        <span
          v-else-if="!send.recipient_segment"
          class="title"
          v-html="postTitle"
        ></span>
        <div v-else>
          {{ send.recipient_segment.recipient_alias }}
        </div>
      </div>
    </div>
    <div
      v-if="isPreview && send.card_template"
      :class="['post-card', 'preview', { rendering: cardRendering }]"
      :style="{
        'background-image': generatedCard
          ? `url('${generatedCard}')`
          : `url('${send.card_template_preview?.thumbnail}')`,
      }"
    ></div>
    <div
      v-if="send.generated_card"
      class="post-card"
      :style="{ 'background-image': `url('${send.generated_card}')` }"
    ></div>

    <div ref="messageContent" :class="['content', { rendering }]"></div>

    <template v-if="!isPreview && !isEmbedded">
      <ReactionBar :read-only="timelineMode" :entity-id="send.id" />
      <CommentBar :read-only="timelineMode" :entity-id="send.id" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.gift-card {
  display: block;

  > .header {
    display: grid;
    grid-template-columns: 1fr 100px;

    > .users {
      display: flex;
      align-items: center;

      .user--card {
        font-size: 14px;

        :deep(.user--profile-picture) {
          width: 32px;
        }

        .timestamp {
          font-size: 12px;
          color: $muted-text;
          line-height: 12px;
          margin: 3px 0 0;
          display: block;
        }
      }

      > i {
        display: block;
        margin: 0 15px;
        color: $gray-300;
      }

      > ul {
        display: flex;
        flex-wrap: wrap;
        row-gap: 8px;
        column-gap: 8px;
        padding: 0;
        margin: 0;

        > li {
          display: block;
        }
      }

      > strong {
        font-size: 14px;
      }
    }
  }

  .post-card {
    overflow: hidden;
    border-radius: $border-radius;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 15px 0;
    position: relative;

    &::before {
      content: '';
      padding: 0 0 56.25%;
      display: block;
    }

    &.preview {
      &::after {
        content: 'PREVIEW';
        background: rgba($light, 0.6);
        padding: 0 5px;
        line-height: 14px;
        font-size: 12px;
        text-transform: uppercase;
        position: absolute;
        right: 15px;
        top: 10px;
        border-radius: 4px;
      }
    }
  }

  > .reaction-bar {
    margin: 15px 0 0;
  }

  > .comment-bar {
    margin: 20px 0 0;
  }

  .content {
    margin: 15px 0 0;

    &.rendering {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &.preview {
    > .header {
      display: block;
    }
  }
}

.recipients-under-threshold {
  > li {
    margin-right: 8px;
  }
}
</style>
