<script setup>
import ProfilePopover from '@/components/ProfilePopover.vue'
import ReactionsDropdown from '@/components/client/ReactionsDropdown.vue'
import PostMetaDropdown from '@/components/client/PostMetaDropdown.vue'
import UserProfileModal from '@/components/client/modals/UserProfile.vue'
import { storeToRefs } from 'pinia'
import { watch, ref, nextTick, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useHead } from '@unhead/vue'
// eslint-disable-next-line import/no-unresolved
import CardBrandSprite from '@/assets/card-brand-sprite.svg?skipsvgo'
import { useHelpWidget } from './utils'
import { useUserStore, useModalStore } from './store'
import { useModal } from './modal'
import TosModal from './components/admin/TOSModal.vue'

const helpWidget = useHelpWidget()

useHead({
  titleTemplate: '%s | HiThrive',
})

const { openModal } = useModal()

const userStore = useUserStore()
userStore.populateUser()

const { user: currentUser } = storeToRefs(userStore)

const isImpersonating = computed(
  () => userStore.user?.type === 'SUPERADMIN_IMPERSONATION'
)

if (isImpersonating.value === true) {
  document.body.classList.add('is-impersonating')
}

// const router = useRouter()

const route = useRoute()
// eslint-disable-next-line no-undef
const footerBuildTag = __HITHRIVE_BUILD_TAG__ ?? 'Build Error'

const requiredDataPopulated = computed(() => {
  const requiresAuth =
    route.matched.findIndex((r) => r.meta?.requireAuth === true) > -1

  return (
    route.matched.length > 0 &&
    (!requiresAuth || (userStore.beenPopulated && userStore.teamPopulated))
  )
})

const modalStore = useModalStore()
const { stack: modalStack } = storeToRefs(modalStore)

const modalStackElem = ref(null)

let hasModalClickListener = false
let clickedOn = null
function handleMouseUp(e) {
  if (e.target !== clickedOn) return
  if (
    !e.target.classList.contains('ht-scrim') &&
    !e.target.classList.contains('ht-modal')
  )
    return

  modalStack.value[0].triggerClose()
}
function handleMouseDown(e) {
  clickedOn = e.target
}
function attachModalClickListener() {
  if (hasModalClickListener) return

  document.body.addEventListener('mousedown', handleMouseDown)
  document.body.addEventListener('mouseup', handleMouseUp)
  hasModalClickListener = true
}

watch(
  () => modalStack.value.length,
  (length) => {
    if (length > 0) {
      if (document.body.classList.contains('has-modal')) return

      document.body.classList.add('has-modal')
      nextTick(() => {
        attachModalClickListener()
      })
    } else {
      document.body.classList.remove('has-modal')
      document.body.removeEventListener('mousedown', handleMouseDown)
      document.body.removeEventListener('mouseup', handleMouseUp)
      hasModalClickListener = false
    }
  }
)

function handleMouseClick(e) {
  const { target } = e
  if (target.classList.contains('user-mention') && currentUser.value) {
    e.preventDefault()

    // Navigate to user profile
    // router.push({
    //   name: 'Client_Users-Profile',
    //   params: { id: target.$HiThriveUser.id },
    // })
    openModal(UserProfileModal, { id: target.$HiThriveUser.id })
  }
}

document.querySelector('html').addEventListener('click', handleMouseClick)

window.addEventListener('keydown', ({ isComposing, keyCode }) => {
  if (isComposing || keyCode === 229 || keyCode !== 27) return

  modalStack.value[0].triggerClose()
})

const dragEvents = ['dragenter', 'dragover', 'dragleave', 'drop']
dragEvents.forEach((eventName) => {
  document.body.addEventListener(eventName, (e) => {
    e.preventDefault()
  })
})
</script>

<template>
  <div
    id="app-wrap"
    :class="{
      loading: !requiredDataPopulated,
    }"
  >
    <tos-modal v-if="currentUser?.roles.includes('admin')" />

    <div v-if="isImpersonating" id="impersonation-header">
      <i class="fas fa-warning"></i> You are impersonating
      {{ currentUser.full_name }} ({{ currentUser.email }})
    </div>
    <card-brand-sprite />

    <profile-popover v-if="currentUser" />
    <reactions-dropdown />
    <post-meta-dropdown />
    <router-view></router-view>

    <div id="app-footer">
      <div class="container-fluid">
        <ul>
          <li>{{ footerBuildTag }}</li>
          <li class="spacer"></li>
          <li>
            <a
              href="https://www.hithrive.com/legal/privacy-policy"
              target="_blank"
              >Privacy Policy</a
            >
          </li>
          <li>
            <a
              href="https://www.hithrive.com/legal/terms-of-service"
              target="_blank"
              >Terms of Service</a
            >
          </li>
          <li>
            <a href="#" @click.prevent="helpWidget.open()" target="_blank"
              >Need help?</a
            >
          </li>
        </ul>
      </div>
    </div>

    <transition-group
      id="modal-stack"
      ref="modalStackElem"
      :class="[{ 'has-multiple': modalStack.length > 1 }]"
      name="modalStack"
      tag="div"
    >
      <component
        :is="modal.component"
        v-for="(modal, index) in modalStack"
        :key="`modal_${modal.id}`"
        :style="{
          'z-index': 999 + (modalStack.length - index),
          '--modalIndex': index,
        }"
        :data="modal.data"
        @attach-close-validator="(fn) => (modal.closeValidator = fn)"
        @modal-close="modal.triggerClose()"
        @modal-done="modal.callback"
      />
    </transition-group>

    <div v-if="modalStack.length > 0" class="ht-scrim"></div>
  </div>

  <div v-if="!requiredDataPopulated" class="loading-splash">
    <flower-loader />
  </div>
</template>

<style lang="scss">
#impersonation-header,
#offboarding-header {
  display: block;
  background: $info;
  height: 40px;
  text-align: center;
  padding: 7px 10px;
  font-weight: 600;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

#offboarding-header {
  background: $warning;
}

.loading-splash {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $light;
}

#app-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  height: 30px;
  border-top: 1px solid #f4efef;
  z-index: 32;
  font-size: 10px;
  transition:
    transform 200ms $curve 1100ms,
    opacity 200ms $curve 900ms;

  ul {
    display: flex;
    padding: 0;
    margin: 0;
    height: 30px;
    align-items: center;
    column-gap: 10px;

    > li {
      display: block;
      margin: 0;
      padding: 0;
      flex: 0 1 auto;

      &.spacer {
        flex: 1;
      }
    }
  }
}

#app-wrap {
  padding-bottom: 30px;
  overflow-x: hidden;

  transition:
    transform 500ms $curve 50ms,
    opacity 350ms $curve;
  transform-origin: center 50vh;

  &.loading {
    transform: scale(1.075);
    opacity: 0;

    #app-footer {
      transform: translateY(100%);
      opacity: 0;
    }
  }

  &.is-offboarding {
    padding-top: 40px;
  }
}
</style>
