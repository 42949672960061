<script setup>
import { useHead } from '@unhead/vue'

useHead({ title: 'Page not found' })
</script>
<template>
  <div class="not-found">
    <div class="empty-content-placeholder">
      <h5>Uh oh, that page doesn't exist!</h5>
      <small
        >Looks like you got a little turned around. Let's get back to
        HiThrive!</small
      >

      <div class="mt-4">
        <a href="/" class="btn btn-white btn-sm">Go back</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.not-found {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
