<script setup>
import { User } from '@/api'
import { useUserStore } from '@/store'
import { ref, watch, inject, nextTick, computed, getCurrentInstance } from 'vue'
import { useToast } from 'vue-toastification'

const userStore = useUserStore()

const stripe = inject('stripe')

const toast = useToast()

const emit = defineEmits(['update:modelValue', 'select'])
const props = defineProps({
  modelValue: Object,
})

const instance = getCurrentInstance()

const hasSelectEmit = computed(() => !!instance?.vnode?.props?.onSelect)

const loading = ref(false)
const shippingAddresses = ref([])
const selectedAddr = ref(null)
const shippingAddress = computed(() =>
  selectedAddr.value
    ? shippingAddresses.value.find(({ id }) => id === selectedAddr.value)
    : null
)
const addShippingAddress = ref(false)

const addrElem = ref(null)
const canAddAddr = ref(false)
const savingAddr = ref(false)

if (hasSelectEmit.value === true) {
  console.log(hasSelectEmit.value)
  watch(selectedAddr, (v) => {
    emit('select', v)
  })
}

watch(shippingAddress, (addr) => {
  emit('update:modelValue', addr)
})

watch(shippingAddresses, (addrs) => {
  if (addrs.length === 0) addShippingAddress.value = true
  else addShippingAddress.value = false

  console.log(hasSelectEmit.value)
  if (addrs.length === 1 && hasSelectEmit.value === false) {
    selectedAddr.value = addrs[0].id
  }
})

async function populateAddresses() {
  loading.value = true
  const [err, resp] = await User.getShippingAddresses()
  loading.value = false

  if (err) {
    toast.error(
      'We had trouble loading your shipping addresses. Please try again.'
    )
    return
  }

  shippingAddresses.value = resp

  selectedAddr.value = props.modelValue?.id ?? null
}
populateAddresses()

let elements
let addrForm
function renderAddressForm() {
  elements = stripe.elements({
    appearance: { theme: 'stripe', labels: 'floating' },
  })

  addrForm = elements.create('address', {
    mode: 'shipping',
    autocomplete: {
      mode: 'google_maps_api',
      apiKey: 'AIzaSyCTQFghcdUYBs0jrZ7h3Cdt8PpL1oxKtlU',
    },
    fields: {
      phone: 'always',
    },
    validation: {
      phone: {
        required: 'always',
      },
    },
  })
  addrForm.mount(addrElem.value)

  addrForm.on('change', (e) => {
    canAddAddr.value = e.complete
  })
}

async function addAddr() {
  const { complete, value } = await addrForm.getValue()

  if (!complete) return

  savingAddr.value = true
  const [err, resp] = await User.addShippingAddress(value)
  await userStore.populateUser()
  savingAddr.value = false

  if (err) {
    toast.error(`Your address couldn't be saved. Please try again.`)
    return
  }

  shippingAddresses.value.push(resp.added)
  selectedAddr.value = resp.added.id
}

watch(addShippingAddress, (v) => {
  if (v === true) {
    nextTick(() => renderAddressForm())
  }
})
</script>

<template>
  <div class="shipping-address-selector">
    <div v-if="shippingAddress" class="selected-address">
      <div class="static-card">
        <div class="address">
          <strong>{{ shippingAddress.name }}</strong>
          <span
            >{{ shippingAddress.address.line1
            }}<span v-if="shippingAddress.address.line2"
              >&nbsp;{{ shippingAddress.address.line2 }}</span
            >, {{ shippingAddress.address.city }},
            {{ shippingAddress.address.state }}
            {{ shippingAddress.address.postal_code }}
            {{ shippingAddress.address.country }}</span
          >
        </div>
        <a href="#" class="btn btn-link" @click.prevent="selectedAddr = null"
          >Change</a
        >
      </div>
    </div>
    <div v-else-if="addShippingAddress">
      <div ref="addrElem" class="address-form"></div>
      <div class="d-flex flex-column row-gap-2">
        <button
          type="button"
          class="btn btn-dark flex-grow-1"
          :disabled="!canAddAddr"
          @click="addAddr"
        >
          <template v-if="!savingAddr">Save address</template>
          <loading-spinner v-else color="dark" />
        </button>
        <button
          v-if="shippingAddresses.length > 0"
          class="btn btn-link"
          @click="addShippingAddress = false"
        >
          Use saved address
        </button>
      </div>
    </div>
    <div v-else>
      <template v-if="!loading">
        <ul class="addr-options">
          <li
            v-for="addr in shippingAddresses"
            :key="`shippingAddr_${addr.id}`"
          >
            <div class="address">
              <strong>{{ addr.name }}</strong>
              <span>{{ addr.address.line1 }}</span>
              <span v-if="addr.address.line2">{{ addr.address.line2 }}</span>
              <span
                >{{ addr.address.city }}, {{ addr.address.state }}
                {{ addr.address.postal_code }} {{ addr.address.country }}</span
              >
              <span>{{ addr.phone }}</span>
            </div>
            <div class="actions d-flex flex-column row-gap-2">
              <button class="btn btn-white" @click="selectedAddr = addr.id">
                Select address
              </button>
              <button
                class="btn btn-link btn-sm"
                @click.prevent="deleteAddress(addr.id)"
              >
                Delete
              </button>
            </div>
          </li>
        </ul>

        <div class="d-grid">
          <button
            class="btn btn-link btn-sm mt-2"
            @click="addShippingAddress = true"
          >
            <i class="fas fa-plus"></i> Add new address
          </button>
        </div>
      </template>

      <loading-spinner v-else color="dark" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.address-form {
  margin: 0 0 15px;
}

.selected-address {
  > .static-card {
    padding: 15px;
    font-size: 14px;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: start;
  }
}

.address {
  min-width: 0;

  > span,
  > strong {
    display: block;
    line-height: 1.25em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.addr-options {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;

  > li {
    display: grid;
    grid-template-columns: 1fr max-content;
    border-bottom: 1px solid $border-color;
    padding: 0 0 10px;
    align-items: center;
  }
}
</style>
