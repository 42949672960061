import { defineAsyncComponent, markRaw } from 'vue'
import slugify from 'slugify'

export const LIST_OF_FEATURES = [
  {
    name: 'Shoutouts',
    id: 'recognition',
    description:
      'Quickly recognize peers for a job well done with a message, points and company value.',
  },
  {
    name: 'Nominations',
    id: 'nominations',
    description: 'Users can participate in nominations by ',
  },
  {
    name: 'Celebrations',
    id: 'celebrations',
    description:
      'Automated birthday, work anniversary and new hire celebrations.',
  },
  {
    name: 'Challenges',
    id: 'challenges',
    description: 'Rewards for completing challenges set by your employer.',
  },
  {
    name: 'Gifting',
    id: 'gifting',
    description: 'Send gifts from HiThrive Rewards ',
  },
]

export const REWARD_CASHOUT_OPTIONS = {
  PAYPAL: {
    supportedRails: ['email'],
    countries: [
      ['AD', 'EUR'],
      ['AR', 'USD'],
      ['AU', 'AUD'],
      ['AT', 'EUR'],
      ['BS', 'USD'],
      ['BH', 'USD'],
      ['BE', 'EUR'],
      ['BM', 'USD'],
      ['BW', 'USD'],
      ['BR', 'USD'],
      ['BG', 'USD'],
      ['CA', 'CAD'],
      ['KY', 'USD'],
      ['CL', 'USD'],
      ['CO', 'USD'],
      ['CR', 'USD'],
      ['HR', 'USD'],
      ['CY', 'USD'],
      ['CZ', 'CZK'],
      ['DK', 'DKK'],
      ['DO', 'USD'],
      ['EC', 'USD'],
      ['SV', 'USD'],
      ['EE', 'USD'],
      ['FO', 'DKK'],
      ['FI', 'EUR'],
      ['FR', 'EUR'],
      ['GF', 'EUR'],
      ['GE', 'USD'],
      ['DE', 'EUR'],
      ['GI', 'USD'],
      ['GR', 'EUR'],
      ['GL', 'DKK'],
      ['GP', 'EUR'],
      ['GT', 'USD'],
      ['HN', 'USD'],
      ['HK', 'HKD'],
      ['HU', 'HUF'],
      ['IS', 'USD'],
      ['IN', 'USD'],
      ['ID', 'IDR'],
      ['IE', 'EUR'],
      ['IL', 'ILS'],
      ['IT', 'EUR'],
      ['JM', 'USD'],
      ['JP', 'JPY'],
      ['JO', 'USD'],
      ['KZ', 'USD'],
      ['KE', 'USD'],
      ['KW', 'USD'],
      ['LV', 'USD'],
      ['LS', 'USD'],
      ['LI', 'CHF'],
      ['LT', 'USD'],
      ['LU', 'EUR'],
      ['MY', 'USD'],
      ['MT', 'USD'],
      ['MQ', 'EUR'],
      ['MU', 'USD'],
      ['MX', 'MXN'],
      ['MD', 'USD'],
      ['MA', 'USD'],
      ['MZ', 'USD'],
      ['NL', 'EUR'],
      ['NZ', 'NZD'],
      ['NI', 'USD'],
      ['NO', 'NOK'],
      ['OM', 'USD'],
      ['PA', 'USD'],
      ['PE', 'USD'],
      ['PH', 'PHP'],
      ['PL', 'PLN'],
      ['PT', 'EUR'],
      ['QA', 'USD'],
      ['RE', 'EUR'],
      ['RO', 'USD'],
      ['SM', 'EUR'],
      ['SA', 'USD'],
      ['SN', 'USD'],
      ['RS', 'USD'],
      ['SG', 'SGD'],
      ['SK', 'USD'],
      ['SI', 'EUR'],
      ['ZA', 'USD'],
      ['KR', 'USD'],
      ['ES', 'EUR'],
      ['SE', 'SEK'],
      ['CH', 'CHF'],
      ['TW', 'USD'],
      ['UA', 'USD'],
      ['AE', 'USD'],
      ['GB', 'GBP'],
      ['US', 'USD'],
      ['UY', 'USD'],
      ['VE', 'USD'],
      ['VN', 'USD'],
    ],
  },
  VENMO: { supportedRails: ['mobile'], countries: [['US', 'USD']] },
}

export const REWARD_COUNTRY_AVAILABILITY = {
  AMAZON: ['AU', 'US', 'CA', 'GB', 'IE', 'IT', 'ES', 'DE', 'PL', 'FR', 'SE'],
  MARKETPLACE: ['US'],
  CASHOUT: [
    ...REWARD_CASHOUT_OPTIONS.PAYPAL.countries.map(([country]) => country),
    ...REWARD_CASHOUT_OPTIONS.VENMO.countries.map(([country]) => country),
  ],
}

export const PAYPAL_PAYOUT_FEES = {
  AUD: 0.32,
  BRL: 0.5,
  CAD: 0.32,
  CZK: 6,
  DKK: 1.5,
  EUR: 0.22,
  HKD: 1.75,
  HUF: 53,
  ILS: 1,
  JPY: 30,
  MXN: 2.75,
  TWD: 8,
  NZD: 0.38,
  NOK: 1.7,
  PHP: 12.5,
  PLN: 0.75,
  RUB: 60,
  SGD: 0.4,
  SEK: 2.25,
  CHF: 0.33,
  THB: 9,
  GBP: 0.17,
  USD: 0.25,
}

export const MAPPABLE_FIELDS = {
  email: 'Email Address',
  first_name: 'First Name',
  last_name: 'Last Name',
  employee_id: 'Employee ID',
  manager_employee_id: 'Manager ID',
  hire_date: 'Hire Date',
  birth_date: 'Birthdate',
  profile_picture: 'Profile Picture',
}

export const USER_ROLES = [
  {
    label: 'Administrator',
    value: 'admin',
  },
  {
    label: 'Challenges Moderator',
    value: 'challenge_moderator',
  },
  {
    label: 'Spot Celebration Moderator',
    value: 'gifting_moderator',
  },
  {
    label: 'Spot Celebration Sender',
    value: 'gifting_sender',
  },
  {
    label: 'Billing Admin',
    value: 'billing_admin',
  },
]

export const HITHRIVE_INTEGRATION_LABELS = {
  SENDOSO: 'Sendoso',
  SWAGLAB: 'HiThrive Swag Lab',
  PRINTFUL: 'Printful',
  SLACK: 'Slack',
  MSTEAMS: 'Microsoft Teams',
}

export const HITHRIVE_ALLOWED_3PS = {
  msteams: {
    icon: 'msteams.svg',
    name: 'Microsoft Teams',
  },
}

export const INTEGRATION_LIST = markRaw([
  {
    slug: 'people-data',
    name: 'People Data',
    integrations: [
      {
        name: 'BambooHR',
        description: '',
        type: 'HRIS',
        settingsModal: defineAsyncComponent({
          loader: () =>
            import(
              '@/components/admin/integrationSettings/BambooHRSettings.vue'
            ),
        }),
        iconName: 'bamboohr.svg',
      },
      {
        name: 'Dayforce',
        description: '',
        type: 'HRIS',
        iconName: 'dayforce.png',
      },
      {
        name: 'FTP/SFTP',
        description: '',
        type: 'HRIS',
        settingsModal: defineAsyncComponent({
          loader: () =>
            import('@/components/admin/integrationSettings/FTPSettings.vue'),
        }),
        iconName: 'ftp.svg',
        cf_name: 'ftp',
        fieldMapping: 'FREEFORM',
      },
      {
        name: 'HiBob',
        description: '',
        type: 'HRIS',
        settingsModal: defineAsyncComponent({
          loader: () =>
            import('@/components/admin/integrationSettings/HiBobSettings.vue'),
        }),
        iconName: 'hibob.svg',
      },
      {
        name: 'Namely',
        description: '',
        type: 'HRIS',
        settingsModal: defineAsyncComponent({
          loader: () =>
            import('@/components/admin/integrationSettings/NamelySettings.vue'),
        }),
        iconName: 'namely.svg',
      },
      {
        name: 'Paylocity',
        description: '',
        type: 'HRIS',
        settingsModal: defineAsyncComponent({
          loader: () =>
            import(
              '@/components/admin/integrationSettings/PaylocitySettings.vue'
            ),
        }),
        iconName: 'paylocity.svg',
      },
      {
        name: 'Sage HR',
        description: '',
        type: 'HRIS',
        iconName: 'sagehr.png',
      },
      {
        name: 'Sage People',
        description: '',
        type: 'HRIS',
        iconName: 'sagepeople.png',
      },
      {
        name: 'TriNet',
        description: '',
        type: 'HRIS',
        settingsModal: defineAsyncComponent({
          loader: () =>
            import('@/components/admin/integrationSettings/TrinetSettings.vue'),
        }),
        iconName: 'trinet.svg',
      },
      {
        name: 'UKG',
        description: '',
        type: 'HRIS',
        iconName: 'ukg.svg',
        settingsModal: defineAsyncComponent({
          loader: () =>
            import('@/components/admin/integrationSettings/UKGSettings.vue'),
        }),
      },
      {
        name: 'Workday',
        description: '',
        type: 'HRIS',
        settingsModal: defineAsyncComponent({
          loader: () =>
            import(
              '@/components/admin/integrationSettings/WorkdaySettings.vue'
            ),
        }),
        iconName: 'workday.svg',
      },
      {
        name: 'Zenefits',
        description: '',
        type: 'HRIS',
        iconName: 'zenefits.svg',
      },
    ]
      .map((integration) => ({
        ...integration,
        slug: integration.slug ?? slugify(integration.name),
      }))
      .sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB)),
  },
  {
    slug: 'rewards-swag',
    name: 'Rewards & Swag',
    integrations: [
      {
        name: 'Sendoso',
        description: '',
        type: 'SWAG',
        settingsModal: defineAsyncComponent({
          loader: () =>
            import(
              '@/components/admin/integrationSettings/SendosoSettings.vue'
            ),
        }),
        iconName: 'sendoso.svg',
      },
      {
        name: 'Printful',
        description: '',
        type: 'SWAG',
        settingsModal: defineAsyncComponent({
          loader: () =>
            import(
              '@/components/admin/integrationSettings/PrintfulSettings.vue'
            ),
        }),
        iconName: 'printful.svg',
      },
    ]
      .map((integration) => ({
        ...integration,
        slug: slugify(integration.name),
      }))
      .sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB)),
  },
  {
    slug: 'unified-comms',
    name: 'Unified Communications',
    integrations: [
      {
        name: 'Slack',
        description: '',
        type: 'COMMS',
        settingsModal: defineAsyncComponent({
          loader: () =>
            import('@/components/admin/integrationSettings/SlackSettings.vue'),
        }),
        iconName: 'slack.svg',
      },
      {
        name: 'Microsoft Teams',
        slug: 'msteams',
        description: '',
        type: 'COMMS',
        settingsModal: defineAsyncComponent({
          loader: () =>
            import(
              '@/components/admin/integrationSettings/MSTeamsSettings.vue'
            ),
        }),
        iconName: 'msteams.svg',
      },
    ]
      .map((integration) => ({
        ...integration,
        slug: integration.slug ?? slugify(integration.name),
      }))
      .sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB)),
  },
])

export const INTEGRATION_LIST_FLATTENED = markRaw(
  INTEGRATION_LIST.map(({ integrations }) => integrations).flat()
)

export const NONCUSTOM_SEGMENT_FIELDS = [
  {
    id: 'is_manager',
    label: 'User is manager',
  },
]

export const REPORT_TYPES = [
  { value: 'SHOUTOUTS', label: 'Shoutouts' },
  { value: 'SHOUTOUTS_BY_RECIPIENT', label: 'Shoutouts by Recipient' },
  { value: 'REDEMPTIONS', label: 'Reward Redemptions' },
  { value: 'CELEBRATIONS', label: 'Celebrations' },
  { value: 'SPOT_CELEBRATIONS', label: 'Spot Celebrations' },
  { value: 'USERS', label: 'Users' },
  { value: 'POINTS', label: 'User Points' },
]

export const MARKETPLACE_CATEGORIES = [
  {
    name: 'Baby',
    id: 'baby',
    icon: 'fa-baby',
  },
  {
    name: 'Candles',
    id: 'candles',
    icon: 'fa-candle-holder',
  },
  {
    name: 'Clothing',
    id: 'clothing',
    icon: 'fa-clothes-hanger',
  },
  {
    name: 'Cooking',
    id: 'cooking',
    icon: 'fa-pan-frying',
  },
  {
    name: 'Coffee & Tea',
    id: 'coffee-tea',
    icon: 'fa-coffee-beans',
  },
  {
    name: 'Drinking',
    id: 'drinking',
    icon: 'fa-martini-glass',
  },
  {
    name: 'Fitness',
    id: 'fitness',
    icon: 'fa-dumbbell',
  },
  {
    name: 'Flowers & Plants',
    id: 'flowers-plants',
    icon: 'fa-flower-tulip',
  },
  {
    name: 'Food',
    id: 'food',
    icon: 'fa-popcorn',
  },
  {
    name: 'Games & Puzzles',
    id: 'games-puzzles',
    icon: 'fa-dice',
  },
  {
    name: 'Gift Boxes',
    id: 'gift-boxes',
    icon: 'fa-gifts',
  },
  {
    name: 'Goods & Gadgets',
    id: 'goods-gadgets',
    icon: 'fa-headphones-simple',
  },
  {
    name: 'Home',
    id: 'home',
    icon: 'fa-couch',
  },
  {
    name: 'Jewelry',
    id: 'jewelry',
    icon: 'fa-gem',
  },
  {
    name: 'Learning',
    id: 'learning',
    icon: 'fa-books',
  },
  {
    name: 'Pet',
    id: 'pet',
    icon: 'fa-paw',
  },
  {
    name: 'Self-care',
    id: 'self-care',
    icon: 'fa-face-smile-hearts',
  },
  {
    name: 'Travel',
    id: 'travel',
    icon: 'fa-plane',
  },
]

export const STANDARD_CELEBRATION_USER_ATTRIBUTES = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'first_name',
    label: 'First Name',
  },
  {
    id: 'last_name',
    label: 'Last Name',
  },
  {
    id: 'bio',
    label: 'Bio',
  },
]

export const STANDARD_NEW_HIRE_USER_ATTRIBUTES = [
  {
    id: 'hireMonth',
    label: 'Hire Month',
  },
  {
    id: 'hireDay',
    label: 'Hire Day',
  },
  {
    id: 'hireYear',
    label: 'Hire Year',
  },
]

export const STANDARD_BIRTHDAY_USER_ATTRIBUTES = [
  {
    id: 'birthdayMonth',
    label: 'Birthday Month',
  },
  {
    id: 'birthdayDay',
    label: 'Birthday Day',
  },
]

export const STANDARD_MILESTONE_USER_ATTRIBUTES = [
  {
    id: 'hireMonth',
    label: 'Hire Month',
  },
  {
    id: 'hireDay',
    label: 'Hire Day',
  },
  {
    id: 'hireYear',
    label: 'Hire Year',
  },
  {
    id: 'milestoneYear',
    label: 'Milestone Year',
  },
  {
    id: 'serviceYears',
    label: 'Service Years',
  },
  {
    id: 'serviceYearsText',
    label: 'Service Years Text; year or years',
  },
  {
    id: 'nthYear',
    label: 'Service Years + ordinal; 1st, 2nd, 3rd, etc',
  },
]

export const CELEBRATION_TYPE_TO_ATTRIBURES = {
  MILESTONE: [
    ...STANDARD_MILESTONE_USER_ATTRIBUTES,
    ...STANDARD_CELEBRATION_USER_ATTRIBUTES,
  ],
  NEW_HIRE: [
    ...STANDARD_NEW_HIRE_USER_ATTRIBUTES,
    ...STANDARD_CELEBRATION_USER_ATTRIBUTES,
  ],
  BIRTHDAY: [
    ...STANDARD_BIRTHDAY_USER_ATTRIBUTES,
    ...STANDARD_CELEBRATION_USER_ATTRIBUTES,
  ],
}

export const CELEBRATION_PRIVACY_DESCRIPTIONS = {
  PUBLIC:
    'Celebration will be shared with team members in recognition feed and included in any celebration announcements.',
  PRIVATE: `Celebration won't be visible to team members and won't be included in any celebration announcements. You'll receive an private notification on the day of your celebration.`,
  SKIP: `Opt-out of this type of celebration. You won't receive any public or private celebrations.`,
}

export const REWARDS_CATALOGS = [
  {
    label: 'Company Catalog',
    value: 'CUSTOM_CATALOG',
  },
  {
    label: 'Marketplace',
    value: 'MARKETPLACE',
  },
  {
    label: 'Amazon',
    value: 'AMAZON',
  },
  {
    label: 'Gift Card',
    value: 'GIFT_CARDS',
  },
  {
    label: 'Cash Out',
    value: 'CASHOUT',
  },
  {
    label: 'Donations',
    value: 'DONATIONS',
  },
]
export const NOM_CAMPAIGN_STATUS = {
  STARTED: 'STARTED',
  ACTIVE: 'ACTIVE',
  SCHEDULED: 'SCHEDULED',
  PENDING: 'PENDING',
  ENDED: 'ENDED',
  COMPLETED: 'COMPLETED',
  ARCHIVED: 'ARCHIVED',
}

export const REWARD_CATALOG_TEXT = {
  CUSTOM_CATALOG: {
    name: 'Custom Catalog',
    description:
      'Offer unique rewards you fulfill internally, or connect to swag providers to offer company swag through your Custom Catalog.',
  },
  GIFT_CARDS: {
    name: 'Gift Cards',
    description:
      'Thousands of gift cards available globally and most sent instantly via email with no markup or hidden fees.',
  },
  AMAZON: { name: 'Amazon', description: '' },
  CASHOUT: {
    name: 'Cash Out',
    description: '',
  },
  MARKETPLACE: {
    name: 'Premium Gifts',
    description:
      'Unique and high quality snacks, electronics, home goods and more from some of the most popular brands.',
  },
  DONATIONS: {
    name: 'Donations',
    description: '',
  },
}
